var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anchor-overview",style:(`background-color: ${_vm.backgroundColor}`)},[_c('div',{staticClass:"anchor-overview__main"},[_c('div',{staticClass:"overview-left-content"},[_c('div',{staticClass:"details-buttons"},[_c('WifiIcon',{attrs:{"signal":_vm.anchor.rssi !== null ? _vm.anchor.rssi : 0,"noConnection":_vm.isAnchorOffline}}),_c('el-tooltip',{attrs:{"content":"Click to see vehicle on map","placement":"left","hide-after":2000}},[_c('el-button',{style:(`background-color: ${
                _vm.selectedAnchor && !_vm.isAnchorOffline ? _vm.anchor.color : '#ffffff'
              }`),attrs:{"disabled":_vm.isAnchorOffline},on:{"click":function($event){return _vm.selectAnchor()}}},[(_vm.selectedAnchor && !_vm.isAnchorOffline)?_c('img',{attrs:{"src":require("@/assets/eye.svg"),"alt":"eye"}}):_c('img',{attrs:{"src":require("@/assets/eye-off.svg"),"alt":"eye-off"}})])],1),_c('el-tooltip',{attrs:{"content":"Vehicle's activity analytics","placement":"left","hide-after":2000}},[_c('el-button',{on:{"click":_vm.redirectToAnalytics}},[_c('img',{attrs:{"src":require("@/assets/bar-chart.svg"),"alt":"bar-chart"}})])],1),_c('el-tooltip',{attrs:{"content":"Vehicle Metrics","placement":"left","hide-after":2000}},[_c('el-button',{on:{"click":_vm.redirectToDashboard}},[_c('i',{staticClass:"el-icon-location-outline"})])],1)],1),_c('div',{staticClass:"anchor-content"},[_c('div',{staticClass:"anchor-content__head"},[_c('span',{staticClass:"head-details"},[_c('el-tooltip',{attrs:{"placement":"top-start","content":_vm.anchor.label,"disabled":!_vm.isTruncated(_vm.anchor.anchorId),"hide-after":2000}},[_c('span',{ref:`label-${_vm.anchor.anchorId}`,staticClass:"info-label"},[_vm._v(_vm._s(_vm.anchor.label))])])],1)]),(_vm.anchor.lastPositionMetadata?.tagPositionId)?_c('div',{staticClass:"anchor-content__last-position"},[_c('span',{staticClass:"last-position-status"},[_vm._v(_vm._s(_vm.anchor.lastPositionMetadata.state === "LEFT" ? "Exited" : _vm.anchor.lastPositionMetadata.state === "ENTERED" ? "At" : "Last Position")+": ")]),_c('div',{staticClass:"last-position-value"},[_c('el-tooltip',{attrs:{"placement":"top-start","content":_vm.getLastAnchorPositionLabel(
                  _vm.anchor.lastPositionMetadata.tagPositionId
                ),"disabled":!_vm.isTruncated(_vm.anchor.lastPositionMetadata.tagPositionId),"hide-after":2000}},[_c('span',{ref:`label-${_vm.anchor.lastPositionMetadata.tagPositionId}`,staticClass:"position-label"},[_vm._v(_vm._s(_vm.getLastAnchorPositionLabel( _vm.anchor.lastPositionMetadata.tagPositionId ))+" ")])]),_c('span',[_vm._v(" "+_vm._s(_vm.lastPositionTime))])],1)]):_c('div',{staticClass:"last-position-value--empty"},[_c('span',[_vm._v("No Info")])])])]),(_vm.anchor.usageInfo && _vm.anchor.usageInfo.routesInfo.length)?_c('div',{staticClass:"metrics-details"},[_c('span',{staticClass:"metrics-label"},[_vm._v("Today")]),_c('div',{staticClass:"metrics-data"},[_c('span',{class:['metrics-value', { valid: _vm.percentageValidToday >= 90 }]},[(_vm.percentageValidToday >= 90)?_c('i',{staticClass:"el-icon-top"}):_vm._e(),_vm._v(" "+_vm._s(_vm.anchorTotalMetrics.validToday)+" valid ("+_vm._s(_vm.percentageValidToday)+"%) ")]),_c('span',{class:['metrics-value', { errors: _vm.percentageErrorsToday >= 10 }]},[(_vm.percentageErrorsToday >= 10)?_c('i',{staticClass:"el-icon-bottom"}):_vm._e(),_vm._v(" "+_vm._s(_vm.anchorTotalMetrics.errorsToday)+" errors ("+_vm._s(_vm.percentageErrorsToday)+"%) ")]),_c('span',{staticClass:"metrics-value"},[_vm._v(" "+_vm._s(_vm.anchorTotalMetrics.avgDurationToday)+" avg duration ")])])]):_vm._e(),_c('div',{class:[
        'recent-routes',
        _vm.anchor.usageInfo?.routesInfo?.length ? 'small-width' : 'large-width'
      ]},[(_vm.anchor.activeRoute || _vm.anchor.recentRoutes?.length)?_c('div',{staticClass:"recent-routes__data"},[_c('RecentRoutesTimeline',{attrs:{"anchor":_vm.anchor,"currentTime":_vm.currentTime}})],1):(!_vm.anchor.usageInfo || _vm.anchor.usageInfo.routesInfo.length)?_c('div',{staticClass:"recent-routes__no-data"},[_c('span',[_vm._v("No Data")])]):(!_vm.anchor.usageInfo.routesInfo.length)?_c('div',{staticClass:"recent-routes__empty"},[_c('span',{staticClass:"empty-title"},[_vm._v("No routes associated")]),_c('span',{staticClass:"empty-subtitle",on:{"click":_vm.redirectToRoutesSettings}},[_vm._v("(Click here to manage this vehicle's routes "),_c('i',{staticClass:"el-icon-right"}),_vm._v(")")])]):_vm._e()]),_c('div',{staticClass:"details-toggle"},[(!_vm.isAnchorInfoVisible)?_c('span',{on:{"click":_vm.toggleAnchorInfo}},[_vm._v("More")]):_c('span',{on:{"click":_vm.toggleAnchorInfo}},[_vm._v("Less")])])]),(_vm.isAnchorInfoVisible)?_c('div',{staticClass:"anchor-overview__expandable-details"},[(_vm.anchor.usageInfo.routesInfo.length)?_c('div',{staticClass:"expandable-details-info"},[_c('span',{staticClass:"expandable-details-info__title"},[_vm._v("Associated Routes")]),_c('div',{staticClass:"expandable-details-info__metrics"},[(_vm.activeRouteUsageInfo)?_c('div',[_c('RouteMetricsCard',{attrs:{"usageInfo":_vm.activeRouteUsageInfo,"isActiveRoute":true,"showLastTwoWeeks":false,"shadow":"never"}})],1):_vm._e(),_vm._l((_vm.allRoutesUsageInfo),function(routesInfo){return _c('div',{key:routesInfo.routeId},[_c('RouteMetricsCard',{attrs:{"usageInfo":routesInfo,"isActiveRoute":false,"showLastTwoWeeks":false,"shadow":"never"}})],1)})],2)]):_vm._e(),_c('div',{staticClass:"expandable-details-info"},[_c('span',{staticClass:"expandable-details-info__title"},[_vm._v("Last Five Positions")]),_c('LastPositionsTable',{attrs:{"lastFivePositions":_vm.anchor.lastFivePositions,"width":"450px"},on:{"show-tag-label-on-map":_vm.onShowTagLabelOnMap}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }