<template>
  <div class="user-api-key">
    <div v-if="users.loading" v-loading="true" class="user-api__loading" />
    <div v-else-if="users.error">
      Something went wront. Try refreshing the current page.
    </div>
    <template v-else>
      <div>
        <h2>API KEY</h2>
      </div>
      <div class="api-key">
        {{ getClientApiKey }}
      </div>
      <div class="api-key__button">
        <el-button
          type="primary"
          class="edit-button"
          size="medium"
          @click.stop="refreshAPI()"
          ><i class="el-icon-refresh"></i>
          Refresh API
        </el-button>
      </div>
      <div class="api-key__documentation">
        <a
          :href="
            isClientPM
              ? 'https://pm-api.azitek.io/docs'
              : 'https://ivt-api.azitek.io/docs'
          "
          target="_blank"
          rel="noopener noreferrer"
          >Documentation</a
        >
      </div>
    </template>
    <el-dialog
      title="Edit Tag"
      :visible.sync="refreshDialog.visible"
      width="300px"
    >
      <div>
        <p class="warning">
          Are you sure that you want to refresh the API key? If you do <br />
          this the previous key will be <br />
          disabled
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="refreshDialog.visible = false"
          :disabled="refreshDialog.loading"
          >Cancel</el-button
        >
        <el-button
          type="danger"
          @click="saveNewAPI()"
          :loading="refreshDialog.loading"
          >Refresh</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types.ts";
import { mapState, mapActions, mapGetters } from "vuex";
import clientsAPI from "@/modules/clients/api/clients.api";

export default {
  computed: {
    ...mapState("clients", {
      users: "users",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user",
      client: "client"
    }),
    ...mapGetters("clients", ["isClientPM"]),

    getClientApiKey() {
      return this.apiKey;
    }
  },

  data() {
    return {
      refreshDialog: {
        visible: false,
        loading: false
      },
      apiKey: ""
    };
  },

  methods: {
    ...mapActions("clients", {
      createUser: actionTypes.CLIENTS_CREATE_USER,
      deleteUser: actionTypes.CLIENTS_DELETE_USER,
      getClient: actionTypes.CLIENTS_GET_CLIENT
    }),

    refreshAPI() {
      this.refreshDialog.visible = true;
    },

    async saveNewAPI() {
      let res = null;
      try {
        res = await clientsAPI.refreshAPI(this.clientData.id);
        this.$notify({
          title: "Success",
          message: "API updated",
          type: "success"
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.refreshDialog.visible = false;
        this.refreshDialog.loading = false;
        this.apiKey = res.data.apiKey;
      }
    }
  },
  created() {
    this.apiKey = this.clientData.apiKey;
  }
};
</script>

<style lang="scss">
.user-api-key {
  padding: 24px 0;

  .api-key {
    margin-top: 2%;
    word-break: break-all;

    @media (max-width: 840px) {
      margin-top: 10px;
      margin-bottom: 15px;
    }

    &__button {
      margin-top: 2%;
    }

    &__documentation {
      display: flex;
      float: right;

      @media (max-width: 840px) {
        margin: 10px 0;
      }

      a {
        text-decoration: underline;
        color: #1f4258;
      }
    }
  }

  .warning {
    white-space: normal;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
