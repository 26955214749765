export const AUTH_LOGIN = "authLogin";
export const AUTH_SESSION_USER = "authSessionUser";

export const ANCHOR_GET_DETAILS = "anchorGetDetails";
export const ANCHOR_GET_ROUTES = "anchorGetRoutes";

export const USER_GET_RESOURCES = "userGetResources";
export const USER_SET_RESOURCES_TAGS = "userSetResourcesTags";
export const USER_GET_ESSENTIAL_INFORMATION = "userGetEssentialInformation";
export const USER_GET_GLOBAL_INFORMATION = "userGetGlobalInformation";
export const USER_GET_USAGE_INFO = "userGetUsageInfo";
export const USER_SET_IS_CHANGING = "userSetIsChanging";
export const USER_SET_DISCARD = "userSetDiscard";
export const USER_SET_PATH = "userSetPath";
export const USER_UPDATE_ACTIVE_ROUTE = "userUpdateActiveRoute";

export const CLIENTS_GET_CLIENT = "clientsGetClient";
export const CLIENTS_GET_ANCHORS = "clientsGetAnchors";
export const CLIENTS_UPDATE_ANCHOR = "clientsUpdateAnchor";
export const CLIENT_UPDATE_ANCHOR_INFO = "clientsUpdateAnchorInfo";
export const CLIENTS_UPDATE_SELECTED_ANCHOR = "clientsUpdateSelectedAnchor";
export const CLIENTS_UPDATE_SELECTED_ANCHOR_PROPERTIES =
  "clientsUpdateSelectedAnchorProperties";
export const CLIENTS_UPDATE_NEXT_POSITION = "clientsUpdateNextPosition";
export const CLIENTS_UPDATE_GLOBAL_VIEW = "clientsUpdateGlobalView";

export const CLIENTS_GET_USERS = "clientsGetUsers";
export const CLIENTS_CREATE_USER = "clientsCreateUser";
export const CLIENTS_DELETE_USER = "clientsDeleteUser";

export const CLIENTS_GET_SSIDS = "clientsGetSSIDS";
export const CLIENTS_CREATE_SSID = "clientsCreateSSID";
export const CLIENTS_UPDATE_SSID = "clientsUpdateSSID";
export const CLIENTS_DELETE_SSID = "clientsDeleteSSID";

export const SET_CURRENT_TIME = "setCurrentTime";
