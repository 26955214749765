<template>
  <el-table
    class="expandable-table"
    @row-click="data => $emit('row-click', data)"
    :data="anchorsData"
    style="width: 100%"
    border
  >
    <el-table-column type="expand">
      <template slot-scope="scope">
        <p class="expandable-table__signal">
          <b>Signal:</b>
          <WifiIcon
            :signal="scope.row.rssi"
            :noConnection="
              scope.row.lastHeartbeat
                ? isAnchorOffline(
                    scope.row.lastHeartbeat.powerSource,
                    scope.row.offlineTs
                  )
                : true
            "
          />
        </p>
        <p class="expandable-table__id" v-if="scope.row.label">
          <b>ID (decimal):</b>
          {{ scope.row.anchorId }}
        </p>

        <p class="expandable-table__travel-rate" v-if="isClientDefault">
          <b>Travel Rate: </b>
          {{ getTravelRate(scope.row.anchorId, "today") }} (Today)
          {{ getTravelRate(scope.row.anchorId, "last-two-weeks") }} (Last Two
          Weeks)
        </p>

        <p class="expandable-table__mac-address">
          <b>MAC Address:</b>
          {{
            scope.row.anchorId
              .toString(16)
              .padStart(12, "0")
              .toUpperCase()
              .match(/.{1,2}/g)
              .join(":")
          }}
        </p>
        <p class="expandable-table__bssid">
          <b>BSSID:</b>
          {{ scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssid : "" }}
        </p>
        <p class="expandable-table__ssid-name">
          <b>SSID Name:</b>
          {{
            scope.row.lastHeartbeat
              ? scope.row.lastHeartbeat.ssidName
                ? scope.row.lastHeartbeat.ssidName
                : "N/A"
              : "N/A"
          }}
        </p>
        <p class="expandable-table__firmware-version" v-if="firmwareVersions">
          <b>FW Version:</b> {{ getFirmwareVersion(scope.row.firmwareId) }}
        </p>
        <p
          :class="
            onOffClasses(
              'power-source',
              scope.row.lastHeartbeat ? scope.row.lastHeartbeat.powerSource : ''
            )
          "
        >
          <b>Power:</b>
          <img src="@/assets/power_socket.svg" alt="Power Source" />
        </p>
        <p class="expandable-table__last-seen">
          <b>Last seen:</b>
          <span
            v-bind:style="{ color: computedColor(scope.row) }"
            v-if="scope.row.lastHeartbeat"
          >
            {{
              moment
                .tz(
                  moment.unix(scope.row.lastHeartbeat.createdDate),
                  clientData.timezone
                )
                .format("HH:mm, DD/MM/YYYY")
            }}
          </span>
        </p>
      </template>
    </el-table-column>
    <el-table-column :resizable="false" label="Gateway">
      <template slot-scope="scope">
        {{
          scope.row.label
            ? scope.row.label
            : scope.row.id
                .toString(16)
                .padStart(12, "0")
                .toUpperCase()
                .match(/.{1,2}/g)
                .join(":")
        }}
      </template>
    </el-table-column>
    <el-table-column
      :resizable="false"
      v-if="isAdmin"
      label="Operations"
      width="100"
    >
      <template slot-scope="scope">
        <div class="edit-buttons">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            ><i class="el-icon-edit"></i
          ></el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import WifiIcon from "@/components/icons/WifiIcon.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    WifiIcon
  },
  props: {
    anchorsData: {
      type: Array
    },
    firmwareVersions: {
      type: Array
    },
    getFirmwareVersion: {
      type: Function
    },
    onOffClasses: {
      type: Function
    },
    computedColor: {
      type: Function
    },
    isAnchorOffline: {
      type: Function
    }
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapGetters("auth", ["isAdmin", "isAzitek"]),
    ...mapGetters("clients", ["isClientDefault"])
  },
  data() {
    return {
      moment
    };
  },
  methods: {
    handleEdit(index, row) {
      this.$emit("handle-edit", index, row);
    },
    getTravelRate(anchorId, date) {
      let usageInfo;
      this.resources.usageInfo.forEach(value => {
        if (anchorId === value.anchorId) usageInfo = value;
      });
      if (date == "today") {
        return usageInfo.dUsage ? usageInfo.dUsage + "%" : "0.00%";
      } else {
        return usageInfo.twUsage ? usageInfo.twUsage + "%" : "0.00%";
      }
    }
  }
};
</script>

<style lang="scss">
.el-table.expandable-table {
  display: none;

  @media (max-width: 1080px) {
    display: block;
  }

  .el-table__expanded-cell {
    padding: 25px 25px 35px;
  }

  .expandable-table__signal {
    display: flex;
    align-items: center;

    .wifi {
      display: inline-block;

      svg {
        @media (max-width: 1080px) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .wifi__no-connection {
      left: 0px;

      @media (max-width: 1080px) {
        width: 20px;
        height: 2px;
        top: 14px;
        left: 5px;
      }
    }
  }

  .expandable-table__id {
    margin-top: 5px;
  }

  .expandable-table__mac-address,
  .expandable-table__travel-rate,
  .expandable-table__bssid,
  .expandable-table__ssid-name,
  .expandable-table__firmware-version {
    margin-top: 12px;
  }

  .user-anchors__power-source {
    text-align: left;

    &::before {
      left: 72px;
      bottom: -18px;
      z-index: 1;

      @media (max-width: 1080px) {
        left: 64px;
        bottom: -8px;
      }
    }

    img {
      position: relative;
      top: 16px;

      @media (max-width: 1080px) {
        width: 27px;
        left: 5px;
        top: 6px;
      }
    }
  }

  .expandable-table__last-seen {
    margin-top: 12px;
  }

  .expandable-table__online-since {
    margin-top: 12px;
  }
}
</style>
