<template>
  <el-dialog title="Save Changes" :width="width" :visible="isVisible">
    <span class="warning"
      >Are you sure you want to save the changes that you made? <br />
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel-save')" size="small"
        >Continue Editing</el-button
      >
      <el-button type="primary" @click="$emit('confirm-save')" size="small"
        >Save Changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "500px"
    }
  }
};
</script>

<style lang="scss" scoped></style>
