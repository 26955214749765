import { ClientsState } from "@/modules/clients/clients.types";

export default {
  isClientDefault(state: ClientsState): boolean {
    return state.data?.type == 0 || state.data?.type == 2 || false;
  },
  isClientDefaultNonTracker(state: ClientsState): boolean {
    return state.data?.type == 0 || false;
  },
  //Cargo Check
  isClientCC(state: ClientsState): boolean {
    return state.data?.type == 1 || false;
  },
  //Process Monitoring
  isClientPM(state: ClientsState): boolean {
    return state.data?.type == 2 || false;
  },
  // Inventory Manager clients
  isClientIM(state: ClientsState): boolean {
    return state.data?.type == 3 || false;
  },
  //Asset Manager
  isClientAM(state: ClientsState): boolean {
    return state.data?.type == 4 || false;
  }
};
