<template>
  <div class="edit-dialog" v-if="isVisible && assetForm">
    <el-dialog
      :title="`Edit ${assetType}`"
      :visible="isVisible"
      :close-on-click-modal="false"
      @close="$emit('update-edit-dialog-visibility', false)"
      :width="windowWidth < 1024 ? '95%' : '920px'"
    >
      <div class="edit-asset-values">
        <span>
          <b>ID</b>
        </span>
        <span class="auto-phrase">
          <b>CURRENT BATTERY</b>
        </span>
        <span>
          <b>ARRIVAL TIME</b>
        </span>
      </div>
      <div class="edit-asset-values">
        <span v-if="assetForm.tagId">
          {{
            assetForm.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("")
          }}
        </span>
        <span>
          <TempBatteryIcon :battery="assetForm.battery" />
        </span>
        <span v-if="assetForm.lastSeen">
          {{
            moment.utc(assetForm.lastSeen * 1000).format("MMM DD YYYY, HH:mm")
          }}
        </span>
      </div>
      <el-form
        label-position="right"
        :model="assetForm"
        :rules="rules"
        ref="assetForm"
        hide-required-asterisk
        @submit.native.prevent
        class="edit-asset-form"
      >
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item prop="label" v-if="assetForm.label">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">Set {{ assetType }} Label</div>
                </el-col>

                <el-col :span="14">
                  <el-input
                    type="text"
                    v-model="assetForm.label"
                    autocomplete="off"
                    @keyup.enter.native="validateForm(true)"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="form-item-lg-margin">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">Set {{ assetType }} Range</div>
                </el-col>

                <el-col :span="14">
                  <div class="slider-col">
                    <el-slider
                      v-model="assetForm.threshold"
                      :marks="marksNonDefault"
                      :step="1"
                      :min="0"
                      :max="16"
                    >
                    </el-slider>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item v-if="isClientDefault">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">Replace {{ assetType }}</div>
                </el-col>

                <el-col :span="14">
                  <el-select
                    v-model="tagToReplace"
                    :placeholder="`Select ${assetType}`"
                    filterable
                    class="select-tags"
                    clearable
                  >
                    <el-option
                      v-for="tag in inactiveTags"
                      :key="`tag.label${tag.id}`"
                      :label="
                        tag.tagId
                          ? `(${tag.tagId
                              .toString(16)
                              .toUpperCase()
                              .match(/.{1,2}/g)
                              .join('')}) ${tag.label}`
                          : ''
                      "
                      :value="tag.id"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="isTracker && assetPrevInfo.type === 0">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">{{ assetType }} Status</div>
                </el-col>

                <el-col :span="14">
                  <el-select
                    v-model="assetForm.status"
                    placeholder="Select Status"
                    filterable
                    class="select-status"
                  >
                    <el-option
                      v-for="status in statusOptions"
                      :key="`tag.status-${status}`"
                      :label="status"
                      :value="status"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="isClientDefault">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">Set {{ assetType }} State</div>
                </el-col>
                <el-col :span="14">
                  <div class="checkbox-col">
                    <el-checkbox
                      v-model="assetForm.active"
                      size="small"
                      @change="onActiveStatusChange"
                      >{{
                        assetPrevInfo.type === 0
                          ? "Installed"
                          : assetForm.active
                          ? "Active"
                          : "Inactive"
                      }}</el-checkbox
                    >
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="isTracker">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">Destroy {{ assetType }}</div>
                </el-col>
                <el-col :span="14">
                  <div class="checkbox-col">
                    <el-checkbox v-model="assetForm.destroy" size="small">{{
                      assetForm.destroy ? "Destroy" : ""
                    }}</el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item v-if="isTracker && assetPrevInfo.type === 1">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :span="10">
                  <div class="form-item-label">Associated Beacon</div>
                </el-col>
                <el-col :span="14">
                  <el-select v-model="assetForm.beaconId" clearable>
                    <el-option
                      v-for="item in resources.data.tags.filter(
                        tag => tag.type === 0
                      )"
                      :key="item.id"
                      :label="`${item.label} (${toHex(item.tagId)})`"
                      :value="item.tagId"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="isTracker && assetPrevInfo.type === 1">
              <el-row :gutter="10" type="flex" justify="start" align="center">
                <el-col :span="10">
                  <div class="form-item-label">{{ assetType }} Status</div>
                </el-col>

                <el-col :span="14">
                  <el-select
                    v-model="assetForm.status"
                    placeholder="Select Status"
                    filterable
                    class="select-status"
                  >
                    <el-option
                      v-for="status in statusOptions"
                      :key="`tag.status-${status}`"
                      :label="status"
                      :value="status"
                    ></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item v-if="isClientDefault && assetPrevInfo.type === 0">
              <el-row :gutter="8" type="flex" justify="start" align="center">
                <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="form-item-label">
                    Child Beacons
                  </div>
                </el-col>
                <el-col :xs="12" :sm="16" :md="16" :lg="16" :xl="16">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Child beacons share log information with their parents. Beacons that are already child beacons cannot have children!"
                    placement="right"
                  >
                    <el-select
                      v-model="childBeaconToAdd"
                      placeholder="Select Beacon"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="tag in orphanTags"
                        :key="`tag.label${tag.id}`"
                        :label="
                          tag.tagId
                            ? `(${tag.tagId
                                .toString(16)
                                .toUpperCase()
                                .match(/.{1,2}/g)
                                .join('')}) ${tag.label}`
                            : ''
                        "
                        :value="tag.tagId"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </el-col>
                <el-col :span="2">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    circle
                    @click="addTagToGroup"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <div>
              <div v-for="tag in selectedChildBeacons" :key="tag.id">
                <el-row :gutter="12" class="child-beacon-list">
                  <el-col :span="16" :offset="8" class="background-animation">
                    <span class="child-beacon-list__tagId">
                      {{
                        tag.id
                          ? `${tag.id
                              .toString(16)
                              .toUpperCase()
                              .match(/.{1,2}/g)
                              .join("")} (${tag.label})`
                          : ""
                      }}
                    </span>
                    <el-button
                      size="small"
                      type="danger"
                      class="el-icon-delete"
                      circle
                      @click="removeTagFromSelected(tag)"
                    />
                  </el-col>
                </el-row>
              </div>
              <div v-if="assetForm.childTags?.length">
                <div
                  class="edit-asset-child-tags"
                  v-for="tag in assetForm.childTags"
                  :key="tag.id"
                >
                  <el-row :gutter="12" class="child-beacon-list">
                    <el-col class="label-div" :span="12" :offset="8">
                      <span class="child-beacon-list__id">
                        {{
                          tag.id
                            ? `${tag.id
                                .toString(16)
                                .toUpperCase()
                                .match(/.{1,2}/g)
                                .join("")} (${tag.label})`
                            : ""
                        }}
                      </span>
                    </el-col>
                    <el-col :span="2" class="child-beacon-list__delete">
                      <el-button
                        size="small"
                        type="danger"
                        class="el-icon-delete"
                        circle
                        @click="removeTag(tag)"
                      />
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>

            <div
              v-if="assetForm.error?.length"
              class="el-form-item__error el-form-item__error--server"
            >
              {{ assetForm.error }}
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-top: -10%">
        <el-button @click="confirmCancel" :disabled="loadingStatus"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="validateForm(false)"
          :loading="loadingStatus"
          >Save</el-button
        >
      </span>
    </el-dialog>
    <SaveChangesDialog
      :isVisible="showCertifySave"
      @cancel-save="showCertifySave = false"
      @confirm-save="finalSaveTag"
    />
    <el-dialog
      title="Discard Changes"
      :visible.sync="showCertifyDiscard"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <span class="warning"
        >You have unsaved changes! <br />
        Are you sure you want to discard them? <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCertifyDiscard = false" size="small"
          >Continue Editing</el-button
        >
        <el-button @click="discardChanges" type="danger" size="small"
          >Discard</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Attention"
      width="400px"
      :visible.sync="showAssociatedRoutes"
      :close-on-click-modal="false"
    >
      <div class="dialog-info">
        The edited beacon belongs to the Route(s) shown below.
      </div>
      <div v-for="route in routesAssociatedToTag" :key="route.id">
        <span>{{ route.label }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import tagsApi from "@/modules/tags/api/tags.api";
import routesApi from "@/modules/routes/api/routes.api";
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import SaveChangesDialog from "@/components/SaveChangesDialog.vue";
import TempBatteryIcon from "./icons/TempBatteryIcon.vue";
import moment from "moment-timezone";

export default {
  name: "EditDialog",
  components: { TempBatteryIcon, SaveChangesDialog },

  props: {
    assetType: {
      type: String,
      default: "Beacon"
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    assetPrevInfo: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      moment,
      marksNonDefault: {
        0: {
          style: {
            color: "#000000",
            width: "40px"
          },
          label: "Min"
        },
        16: {
          style: {
            color: "#000000",
            width: "40px"
          },
          label: "Máx"
        }
      },
      statusOptions: ["NO STATUS", "NOK", "OK", "PRODUCING"],
      assetForm: {
        tagId: 0,
        label: "",
        active: false,
        threshold: null,
        battery: -1,
        lastSeen: -1,
        error: "",
        status: "NO STATUS",
        childTags: [],
        beaconId: null,
        destroy: false
      },
      tagToReplace: null,
      childBeaconToAdd: null,
      orphanTags: [],
      showCertifySave: false,
      selectedChildBeacons: [],
      tagsToDelete: [],
      tagsToSend: [],
      inactiveTags: [],
      loadingStatus: false,
      showAssociatedRoutes: false,
      routesAssociatedToTag: [],
      routeList: [],
      tempOrphanTags: [],
      parentInfo: {},
      showCertifyDiscard: false,
      windowWidth: window.innerWidth
    };
  },

  watch: {
    isVisible(newValue) {
      if (newValue && this.assetPrevInfo) {
        this.updateTempOrphanTags();
        this.orphanTags = this.tempOrphanTags.filter(
          t => t.id !== this.assetPrevInfo.id
        );
        this.inactiveTags = this.resources.data.tags.filter(
          tag => tag.active === false
        );
        this.parentInfo = this.resources.data.tags.find(elem => {
          return elem.id === this.assetPrevInfo.parentId;
        });
        this.assetForm.label = this.assetPrevInfo.label;
        this.assetForm.id = this.assetPrevInfo.id;
        this.assetForm.active = this.assetPrevInfo.active;
        this.assetForm.threshold = this.thresholdToRange(
          this.assetPrevInfo.threshold
        );
        this.assetForm.battery = this.assetPrevInfo.battery;
        this.assetForm.lastSeen = this.assetPrevInfo.lastUpdate;
        this.assetForm.tagId = this.assetPrevInfo.tagId;
        this.assetForm.childTags = this.assetPrevInfo.childTags;

        // Beacons
        if (this.isTracker) {
          this.assetForm.status = this.assetPrevInfo.status;
        }
        // Tags
        if (this.isTracker && this.assetPrevInfo.type === 1) {
          this.assetForm.beaconId = this.assetPrevInfo.beaconId;
        }
      } else {
        this.assetForm = {
          tagId: 0,
          label: "",
          active: false,
          threshold: null,
          battery: -1,
          lastSeen: -1,
          error: "",
          status: "NO STATUS",
          childTags: [],
          beaconId: null,
          destroy: false
        };
        this.selectedChildBeacons = [];
        this.tagsToSend = [];
        this.tagsToDelete = [];
        this.tagToReplace = null;
      }
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapState("clients", {
      clientAnchors: "anchors",
      clientData: "data"
    }),
    ...mapGetters("auth", ["isTracker"]),
    ...mapGetters("clients", ["isClientDefault", "isClientDefaultNonTracker"])
  },

  methods: {
    ...mapActions("user", {
      userSetResourcesTags: actionTypes.USER_SET_RESOURCES_TAGS
    }),
    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    },
    thresholdToRange(threshold) {
      return threshold >= -16 ? 0 : -threshold - 16;
    },
    sortByLastUpdate(arr) {
      arr.sort((a, b) => {
        if (a.lastUpdate < b.lastUpdate) return 1;
        else return -1;
      });
    },
    isTagOutdated(tag) {
      const oneWeekAgo = Math.ceil(
        moment()
          .tz(this.clientData.timezone)
          .subtract(7, "days")
          .valueOf() / 1000
      );
      if (tag.lastUpdate < oneWeekAgo) return true;
      return false;
    },
    orderTags(tagList) {
      const noBatteryTags = tagList.filter(
        tag => tag.battery === 0 && tag.active
      );
      this.sortByLastUpdate(noBatteryTags);

      const tagsNotSeen = tagList.filter(
        tag => this.isTagOutdated(tag) && tag.battery != 0 && tag.active
      );
      this.sortByLastUpdate(tagsNotSeen);

      const badTags = [].concat(noBatteryTags).concat(tagsNotSeen);

      const restOfTags = tagList.filter(
        tag => tag.battery != 0 && badTags.indexOf(tag) === -1 && tag.active
      );
      this.sortByLastUpdate(restOfTags);

      const notActiveTags = tagList.filter(tag => !tag.active);
      this.sortByLastUpdate(notActiveTags);

      const sortedTagList = badTags.concat(restOfTags).concat(notActiveTags);

      this.userSetResourcesTags(sortedTagList);
    },
    async updateTagsList() {
      try {
        const { data } = await tagsApi.getRefreshStatus();
        const updatedTagList = data;
        updatedTagList.forEach(elem => {
          elem.childTags = [];
        });
        updatedTagList.forEach(element => {
          const index = updatedTagList.findIndex(
            elem => elem.id == element.parentId
          );
          if (index !== -1) {
            updatedTagList[index].childTags.push({
              id: element.tagId,
              label: element.label,
              parentId: element.parentId
            });
          }
        });
        this.orderTags(updatedTagList);
      } catch (_) {
        // DO nothing
      }
    },
    async updateTag() {
      this.loadingStatus = true;
      const threshold = -this.assetForm.threshold - 16;

      try {
        if (this.tagToReplace) this.replaceTag(this.assetPrevInfo);

        const payload = {
          label: this.assetForm.label,
          active: this.assetForm.active,
          threshold: threshold,
          childBeacons: this.tagsToSend,
          beaconsToRemove: this.tagsToDelete
        };

        if (this.isTracker) {
          payload.status = this.assetForm.status;

          if (this.assetPrevInfo.type === 1) {
            const foundTag = this.resources.data.tags.find(
              tag => tag.tagId == this.assetForm.beaconId
            );
            payload.beaconId = foundTag ? foundTag.id : null;
          }
        }

        const result = await tagsApi.updateTag(this.assetForm.tagId, payload);

        if (result.data) {
          this.updateTagsList();
          this.$emit("tag-updated", this.tagsToSend, this.tagsToDelete);
        }

        this.assetForm = {
          tagId: 0,
          label: "",
          active: false,
          threshold: null,
          battery: -1,
          lastSeen: -1,
          error: "",
          status: "NO STATUS",
          childTags: [],
          beaconId: null,
          destroy: false
        };
        this.selectedChildBeacons = [];
        this.tagsToSend = [];
        this.tagsToDelete = [];
        this.$emit("update-edit-dialog-visibility", false);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.loadingStatus = false;
      }
    },
    async destroyTag() {
      try {
        const res = await tagsApi.updateTag(this.assetPrevInfo.tagId, {
          destroyed: true
        });

        const tagList = this.resources.data.tags;
        const index = tagList.findIndex(
          tag => tag.tagId === this.assetPrevInfo.tagId
        );
        const updatedTag = this.assetPrevInfo;

        if (index != -1) {
          updatedTag.destroyed = res.data.destroyed;
          tagList[index] = updatedTag;
          this.userSetResourcesTags(tagList);
          this.$emit("tag-destroyed", updatedTag.tagId, updatedTag.destroyed);
        }
        this.$notify({
          title: "Success",
          message: "Beacon deleted",
          type: "success"
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        console.log("deleted");
      }
    },
    saveTag() {
      if (!this.assetForm.destroy) {
        this.updateTag();
      } else {
        this.destroyTag();
      }
    },
    discardChanges() {
      this.showCertifyDiscard = false;
      this.$emit("update-edit-dialog-visibility", false);
    },
    confirmCancel() {
      if (
        this.selectedChildBeacons.length != 0 ||
        this.tagToReplace ||
        this.assetForm.beaconId != this.assetPrevInfo.beaconId
      )
        this.showCertifyDiscard = true;
      else this.$emit("update-edit-dialog-visibility", false);
    },
    confirmSave() {
      if (
        this.selectedChildBeacons.length != 0 ||
        this.tagToReplace ||
        this.assetForm.beaconId != this.assetPrevInfo.beaconId
      )
        this.showCertifySave = true;
      else this.saveTag();
    },
    finalSaveTag() {
      this.saveTag();
      this.showCertifySave = false;
    },
    validateForm(saveTag) {
      this.$refs.assetForm.validate(async valid => {
        if (valid) {
          if (saveTag) {
            this.saveTag();
          } else {
            this.confirmSave();
          }
        }
      });
    },
    addTagToGroup() {
      const index = this.resources.data.tags.findIndex(
        elem => elem.tagId === this.childBeaconToAdd
      );
      this.selectedChildBeacons.push({
        id: this.childBeaconToAdd,
        label: this.resources.data.tags[index].label,
        parentId: this.assetPrevInfo.id
      });
      this.tagsToSend.push(this.childBeaconToAdd);
      this.orphanTags = this.orphanTags.filter(
        elem => elem.tagId !== this.childBeaconToAdd
      );
      this.childBeaconToAdd = null;
    },
    removeTagFromSelected(tag) {
      this.selectedChildBeacons = this.selectedChildBeacons.filter(
        elem => elem.id !== tag.id
      );
      this.tagsToSend = this.tagsToSend.filter(elem => elem !== tag.id);
      this.orphanTags.push(
        this.resources.data.tags[
          this.resources.data.tags.findIndex(elem => elem.tagId === tag.id)
        ]
      );
      // this.$forceUpdate();
    },

    removeTag(tag) {
      this.assetForm.childTags = this.assetForm.childTags.filter(
        elem => elem.id !== tag.id
      );
      this.tagsToDelete.push(tag.id);
      this.orphanTags.push(
        this.resources.data.tags[
          this.resources.data.tags.findIndex(elem => elem.tagId === tag.id)
        ]
      );
      // this.$forceUpdate();
    },
    async replaceTag(tagInfo) {
      try {
        const tag = this.resources.data.tags.find(
          tag => tag.id === this.tagToReplace
        );
        const oldTag = this.resources.data.tags.find(
          tag => tag.id === tagInfo.id
        );

        const lastPosition = oldTag.lastPosition;
        await positionLogsApi.updateTagPosition(tagInfo.id, {
          tagId: tag.id,
          x: lastPosition.x,
          y: lastPosition.y
        });
        this.tagToReplace = null;
        this.$notify({
          title: "Success",
          message: `Beacon Replaced`,
          type: "success"
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
    },

    onActiveStatusChange() {
      if (this.isClientDefaultNonTracker && !this.assetForm.active) {
        this.routesAssociatedToTag = this.routeList.filter(route => {
          return route.tags.some(tag => tag.id == this.assetForm.tagId);
        });
        if (this.routesAssociatedToTag.length > 0) {
          this.showAssociatedRoutes = true;
        }
      }
    },
    checkTagsThatAreInRoute() {
      this.resources.data.tags.forEach(tag => {
        tag.inRoute = this.routeList.some(route =>
          route.tags.some(routeTag => routeTag.id === tag.tagId)
        );
      });
    },
    async fetchRouteList() {
      try {
        const res = await routesApi.getRouteList();
        this.routeList = res.data;
        this.checkTagsThatAreInRoute();
      } catch (error) {
        console.log(error);
      }
    },
    updateTempOrphanTags() {
      let totalTags = [];
      if (this.clientAnchors.data) {
        this.clientAnchors.data.forEach(elem => {
          if (elem.activeRoute)
            if (elem.activeRoute.tags)
              totalTags = totalTags.concat(elem.activeRoute.tags);
        });
      } else {
        this.clientAnchors.forEach(elem => {
          if (elem.activeRoute)
            if (elem.activeRoute.tags)
              totalTags = totalTags.concat(elem.activeRoute.tags);
        });
      }
      this.tempOrphanTags = this.resources.data.tags.filter(
        tag =>
          tag.numberOfChildren === 0 &&
          tag.parentId == null &&
          tag.active &&
          !totalTags.some(t => t.tagPosition.tagId === tag.id)
      );
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  created() {
    if (this.isClientDefaultNonTracker) {
      this.fetchRouteList();
    }
    this.updateTempOrphanTags();
  }
};
</script>

<style lang="scss" scoped>
.edit-dialog {
  .el-dialog {
    width: 920px !important;

    @media (max-width: $md) {
      width: 98vw !important;
      display: flex;
      flex-direction: column;
    }
  }

  .background-animation {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;

    animation-name: animation;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
    background-color: white;
    // width: 83%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;
    border-radius: 5px;
  }

  .child-beacon-list {
    display: flex;
    margin-top: 6px;

    .label-div {
      display: flex;
      align-items: center;
    }

    &__id {
      padding: 3px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    // &__delete {
    //   float: right;
    //   position: absolute;
    //   right: 10px;
    // }
  }

  .edit-asset-values {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-bottom: 2%;

    .auto-phrase {
      word-break: auto-phrase;
    }
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  .form-item-lg-margin {
    margin-bottom: 40px;
  }

  .form-item-label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #606266;
    text-align: left;
  }

  .edit-asset-form {
    border-top: 1px solid black;
    margin-top: 20px;
    padding: 3% 10px 0;

    @media (max-width: $xs) {
      padding: 3% 5px 0;
    }

    // .edit-asset-range-level {
    //   .el-form-item__label {
    //     padding-top: 10px;
    //   }
    // }

    // .edit-asset-replace {
    //   margin-top: 13%;
    //   display: flex;
    //   align-items: center;

    //   .select-tags {
    //     width: 95%;
    //     margin-left: 32px;
    //   }
    // }

    // .edit-asset-status {
    //   display: flex;
    //   justify-content: left;
    //   align-items: center;
    //   margin-top: 20px;

    //   .el-checkbox {
    //     margin-left: 45%;
    //   }
    //   .select-status {
    //     width: 90%;
    //     margin-left: 32px;
    //   }
    // }

    // .edit-asset-select-tags {
    //   padding-left: 14px;
    //   width: 115%;

    //   @media (max-width: 860px) {
    //     width: 100%;
    //   }
    // }

    // .sm-form-item {
    //   display: grid;
    //   grid-template-columns: 1% 35% 55% 1%;
    //   align-items: center;
    //   justify-items: self-start;
    //   height: min-content;
    //   margin-bottom: 20px;

    //   .el-form-itemcontent {
    //     width: 100% !important;
    //     text-align: left !important;
    //   }
    // }

    .add-tag-btn {
      padding-left: 67px;

      @media (max-width: 860px) {
        padding-left: 22px;
      }
    }

    // .edit-asset-child-tags {
    //   display: grid;
    //   grid-template-columns: 32% 68%;
    //   margin-bottom: 3%;
    // }

    .edit-asset-children {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    // .edit-asset-label {
    //   display: flex;
    //   justify-content: left;
    //   align-items: center;

    //   .el-input {
    //     margin-left: 12%;
    //   }
    // }
  }

  .checkbox-col {
    display: flex;
    align-items: center;
  }

  .slider-col {
    padding: 0 12px;
  }

  .el-select {
    width: 100%;

    .el-input__inner {
      width: 100%;
    }
  }
}
</style>
